import { css } from '@emotion/css';
import { Yellow } from '@snapchat/snap-design-system-marketing';
import { type FC, useEffect, useLayoutEffect } from 'react';

import { totalHeaderHeightCssVar } from '../../../components/Header/headerSizeUtils';
import { snapHeaderBackgroundVar } from '../styleConstants';

const homepageCss = css`
  margin-top: calc(0px - var(${totalHeaderHeightCssVar}));
  padding-top: var(${totalHeaderHeightCssVar});
  background: radial-gradient(circle farthest-side, white, ${Yellow.V100});
  min-height: 100vh;
  min-width: 100vw;
`;

export const SnapHome: FC = () => {
  useLayoutEffect(() => {
    document.body.style.setProperty(snapHeaderBackgroundVar, 'transparent');
    return () => {
      document.body.style.removeProperty(snapHeaderBackgroundVar);
    };
  });
  useEffect(() => {
    const scrollListener = () => {
      const opacityOneLimit = 64;
      const opacity = Math.min(opacityOneLimit, window.scrollY) / opacityOneLimit;
      // NOTE: 255, 252, 0 is the Snap Yellow.
      document.body.style.setProperty(snapHeaderBackgroundVar, `rgba(255, 252, 0, ${opacity})`);
    };
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  });
  return <section className={homepageCss}>Future snap homepage.</section>;
};
